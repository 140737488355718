import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Services
import api from 'services/api';
import {
  formatDate,
  truncateIntegerNumber,
  truncateDecimalNumber,
  truncateTime,
} from 'services/valueFormatter';

// Components
import Layout from 'components/Layout/Layout';
import Card from 'components/common/Card';
import MetricCard from 'components/common/MetricCard';
import ClassifierCard from 'components/Requester/ClassifierCard';
import Stamp from 'components/common/Stamp';
import TopURITable from 'components/Requester/TopURITable';
import RequestsChart from 'components/Requester/RequestsChart';
import RequestsTable from 'components/Requester/RequestsTable';
import SessionsTable from 'components/Requester/SessionsTable';

const Requester = () => {
  const { requesterId: currentRequester } = useParams();
  const currentLogger = useSelector((state) => state.global.currentLogger);

  const perPage = 10;

  // fetched Data
  const [requester, setRequester] = useState({});
  const [topURIs, setTopURIs] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [labels, setLabels] = useState([]);
  // requests table
  const [suspiciousRequests, setSuspiciousRequests] = useState([]);
  const [requests, setRequests] = useState([]);
  const [numPages, setNumPages] = useState(0);
  // component state
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // const [curPage, setCurPage] = useState(1);
  // const [nRequests] = useState(20);

  /**
   * Fetches for log data.
   */
  const fetchRequester = useCallback(() => {
    setIsLoading(true);
    setIsError(false);
    api.getRequester(currentLogger, currentRequester, perPage, 1, (res) => {
      if (res.data.status === 200) {
        setRequester(res.data.requester);
        setTopURIs(res.data.topURIs);
        setChartData(res.data.chartData);
        setSuspiciousRequests(res.data.suspiciousRequests);
        setRequests(res.data.requests);
        setNumPages(res.data.numPages);
        setGroups(res.data.groups);
        setLabels(res.data.labels);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [currentLogger, currentRequester]);

  /**
   * Calls fetchRequester onload.
   */
  useEffect(() => {
    fetchRequester();
  }, [fetchRequester]);

  return (
    <Layout title={'Requester'} loading={isLoading} error={isError}>
      <div className="columns is-desktop is-multiline">
        <div className="column is-one-third-widescreen is-half-desktop">
          <MetricCard
            icon="fa-network-wired"
            title="IP Address"
            value={requester.ipAddress}
          />
        </div>
        <div className="column is-one-third-widescreen is-half-desktop">
          <MetricCard
            icon="fa-globe-europe"
            title="Location"
            value={requester.location}
          />
        </div>
        <div className="column is-one-third-widescreen is-half-desktop">
          <MetricCard icon="fa-globe" title="ISP" value={requester.isp} />
        </div>
      </div>

      <div className="columns is-desktop is-multiline">
        <div className="column is-one-third-widescreen is-full-desktop">
          <ClassifierCard
            requester={requester}
            labels={labels}
            groups={groups}
          />
        </div>
        <div className="column is-two-thirds-widescreen is-full-desktop">
          <div className="columns is-desktop is-multiline">
            <div className="column is-half-widescreen is-half-desktop">
              <MetricCard
                icon="fa-paper-plane"
                title="Total Requests"
                value={truncateIntegerNumber(requester.totalRequests)}
              />
            </div>
            <div className="column is-half-widescreen is-half-desktop">
              <MetricCard
                icon="fa-question"
                title="Average Queries per Request"
                value={truncateDecimalNumber(requester.avgQueries)}
              />
            </div>
            <div className="column is-half-widescreen is-half-desktop">
              <MetricCard
                icon="fa-stopwatch"
                title="Average Processing Time per Request"
                value={truncateTime(requester.avgTimeTaken)}
              />
            </div>
            <div className="column is-half-widescreen is-half-desktop">
              <MetricCard
                icon="fa-users"
                title="Total Sessions"
                value={truncateIntegerNumber(requester.totalSessions)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="columns is-desktop is-multiline">
        <div className="column is-two-thirds-widescreen is-half-desktop">
          <MetricCard
            icon="fa-server"
            title="Reverse DNS"
            value={requester.dns}
            truncate={false}
          />
        </div>
        <div className="column is-one-third-widescreen is-half-desktop">
          <MetricCard
            icon="fa-shield-alt"
            title="Bot Level"
            value={requester.botLevel}
          />
        </div>
      </div>
      <div className="columns is-desktop is-multiline">
        <div className="column is-full-widescreen is-half-desktop">
          <MetricCard
            icon="fa-flag"
            title="User Agent Flags"
            value={requester.uaFlag}
            truncate={false}
          />
        </div>
      </div>

      <div className="columns is-desktop is-multiline">
        <div className="column is-one-third-widescreen is-half-desktop">
          <Card>
            <Stamp
              className="mb-4"
              title="First Access"
              value={formatDate(requester.firstAccess)}
            />
            <Stamp
              title="Last Access"
              value={formatDate(requester.lastAccess)}
            />
          </Card>
        </div>
        <div className="column is-one-third-widescreen is-half-desktop">
          <Card>
            <Stamp
              className="mb-4"
              title="Favorite Browser"
              value={requester.favoriteBrowser}
            />
            <Stamp title="Favorite Engine" value={requester.favoriteEngine} />
          </Card>
        </div>
        <div className="column is-one-third-widescreen is-half-desktop">
          <Card>
            <Stamp
              className="mb-4"
              title="Favorite Hardware Device"
              value={requester.favoriteDevice}
            />
            <Stamp title="Favorite OS" value={requester.favoriteOS} />
          </Card>
        </div>
      </div>
      <div className="columns is-desktop is-multiline">
        <div className="column is-one-third-widescreen is-full-desktop">
          <TopURITable topURIs={topURIs} />
        </div>
        <div className="column is-two-thirds-widescreen is-full-desktop">
          <RequestsChart chartData={chartData} />
        </div>
      </div>
      <div className="columns">
        <div className="column is-full">
          <RequestsTable requests={requests} numPages={numPages} />
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <SessionsTable numPages={1} />
        </div>
      </div>

      {suspiciousRequests.length > 0 && (
        <div className="columns">
          <div className="column is-full">
            <RequestsTable
              requests={suspiciousRequests}
              numPages={1}
              tableTitle={'Suspicious Requests'}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Requester;
