import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

import { formatDate, formatElapsedTime } from 'services/valueFormatter';
import Paginator from 'components/common/Paginator';
import Card from 'components/common/Card';
import api from 'services/api';

const SessionsTable = ({ numPages = 1, tableTitle='Sessions' }) => {

  const { requesterId: currentRequester } = useParams();
  const currentLogger = useSelector(state => state.global.currentLogger);

  const perPage = 10;

  const [currentSessions, setCurrentSessions] = useState([]);
  const [currentNumPages, setCurrentNumPages] = useState(numPages);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getRequesterSessions = useCallback((page) => {
    setIsLoading(true);
    setIsError(false);
    api.getRequesterSessions(currentLogger, currentRequester, perPage, page, res => {
      if (res.data.status === 200) {
        setCurrentSessions(res.data.sessions);
        setCurrentNumPages(res.data.numPages);
        setCurrentPage(page);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, [currentLogger, currentRequester]);

  const getSessionsRow = () => (
    currentSessions.map(({ id, numberRequests, duration, avgInterval, stdInterval, percentageRequestReferer, numberSessionIds, username, lastRequest }) => (
      <tr
        key={id}
      >
        <td className="is-size-7">
          {numberRequests}
        </td>
        <td className="is-size-7">
          {formatElapsedTime(duration)}
        </td>
        <td className="is-size-7">
          {avgInterval}
        </td>
        <td className="is-size-7">
          {stdInterval}
        </td>
        <td className="is-size-7">
          {percentageRequestReferer}
        </td>
        <td className="is-size-7">
          {numberSessionIds}
        </td>
        <td className="is-size-7">
          {username}
        </td>
        <td className="is-size-7">
          {formatDate(lastRequest)}
        </td>
      </tr>
    ))
  );

  useEffect(() => {
    getRequesterSessions(1);
  }, [getRequesterSessions]);

  return (
    <Card>
      <h3 className="has-text-weight-bold has-text-light">
        {tableTitle}
      </h3>
      {isLoading}
      {isError}
      <table className="table is-fullwidth is-narrow is-striped">
        <thead>
          <tr>
            <th>Number of Requests</th>
            <th>Duration</th>
            <th>Average Interval</th>
            <th>Standard Deviation</th>
            <th>Percentage of Requests Referer</th>
            <th>Number of Sessions IDs</th>
            <th>Username</th>
            <th>Last Request</th>
          </tr>
        </thead>
        <tbody>
          {getSessionsRow()}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={8}>
              <Paginator
                currentPage={currentPage}
                pagesNum={currentNumPages}
                callBackUrl={getRequesterSessions}
                setLoading={setIsLoading}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
};

SessionsTable.propTypes = {
  numPages: PropTypes.number,
  tableTitle: PropTypes.string
};

export default SessionsTable;
